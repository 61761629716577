
import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import * as styles from '../../assets/css/surveys.module.css'

const SurveyBuch = () => {

  return (
    <Layout>
      <section>
        <h1>Seaside Town Architect’s Survey</h1>
        <h2>Ernesto Buch</h2>
        <section>
          <p className={styles.bold}>When did you serve as Seaside Town Architect?</p>
          <p>From August 1983 to October 1984</p>

          <p className={styles.bold}>What initially drew you to Seaside?</p>
          <p>I was drafted by Andres, due, no doubt, to a certain ideological kinship.</p>

          <p className={styles.bold}>What do you consider your greatest accomplishments during your time as Town Architect?</p>
          <p>The Tupelo Street Beach Pavilion, the first of the beach pavilions and my first building. This actually pre-dates my term as town architect, as it was designed during my time in the Night Crew. The pavilion immediately became emblematic of Seaside, and began appearing in all sorts of advertising campaigns and fashion spreads, for which I was never given credit. My friend, Melanie taylor used to joke that I was the most famous anonymous architect in the country. Actually, I was quite flattered, as I felt I had designed a true piece of vernacular architecture.</p>

          <p className={styles.bold}>How do you feel about Seaside today?</p>
          <p>This is a complex question requiring a long answer, and I would leave that for the conference. Obviously Seaside was a fundamental step in the development of New Urbanism thought. But Seaside is nevertheless a built project, not an ideal one, and as such, it is not perfect. Enough time has elapsed, however, for it to be judged and evaluated, as well as to learn from its successes and from its failures.</p>

          <p className={styles.bold}>Do you consider yourself an Urbanist or New Urbanist? How do you feel Seaside fits in with modern urban philosophy?</p>
          <p>I consider myself an urbanist, period, with no qualifiers. I am a lover of cities, by which I include towns. Although I sometimes use the term traditional urbanism, I feel it is somewhat redundant, as all urbanism is traditional. There is no such thing as untraditional urbanism. Modernist planning principles are anti-urban. The suburban sprawl of the last 65 years is the anti-city.</p>

          <p>Seaside, although a resort development and not (yet) a real town, is nevertheless a real attempt to create the urban form of traditional American small towns through the application of town building principles, something very different from contemporary urban sprawl development and the typical gated “community. It is telling that it has been an economic success.</p>

          <p className={styles.bold}>Can you give a brief summary of what you are doing today, including any recent projects, awards, or positions?</p>
          <p>I first established my practice in New Haven, Connecticut. There I was able to put into practice what I had learned while working for Allan Greenberg of the classical language of architecture, the proper use of the orders and correct detailing. Two projects of this time are exemplary of this. A library for John Richardson, a classical pavilion with a Greek Doric portico on the grounds of his country house, for which I was given the Arthur Ross Award in 1994, and an addition to Oscar de la Renta’s farmhouse in Connecticut, a large room whose interior detailing is informed by 18th Century American wood architecture.</p>

          <p>I have spent a good part of the last fifteen years in Punta Cana, Dominican Republic, back in the Caribbean, while managing to maintain a presence in Miami and Connecticut. I first came to Punta Cana in 1996 to oversee the building of a veranda house facing the sea I had designed for Oscar de la Renta. I stayed on and set up a studio here, where I have been building houses by the sea ever since. Besides in the Dominican Republic, I have recently been involved with the design of houses built in Florida and the Bahamas, as well as an un- built one in Barbados, all looking at the sea. Funny, how things tend to go in circles.</p>

          <p>I have been honored to have received the Charles A. Barrett Award in 2008, awarded by the Florida chapter of the Congress for the New Urbanism. Doubly honored, I should say, as Charles was a dear and now departed friend, a true architectural genius and fellow classicist.</p>
          <hr />
        </section>
        <div className={styles.center}><Link to='/essays/town-architects'>Back to Town Architects</Link></div>
      </section>
    </Layout>
  )
}

export default SurveyBuch
